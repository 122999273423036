.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.overrideMe {
  width: 40%;

  overflow-wrap: break-word;
  display: block;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
#discord-button .icon {
  width: 25px;
  height: 20px;
  margin-right: -5px;
}
#discord-button svg {
  fill: white;
  height: 95%
}

#discord-button a {
  color: white;
  font: normal normal normal 12px/18px 'Helvetica Neue',Arial,sans-serif;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  padding: 1px 9px;
  background-color: #7289da;
  text-decoration: none;
}
#discord-button a:hover {
  background-color: #3e4d84;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.dunexyz {
  background-color: #fff
}

/* Timeline */
.rtl .card-timeline .timeline .timeline-footer .btn {
  margin-left: 0;
  margin-right: auto; }

.darkness {
  background: linear-gradient(
          216.56deg, #030a13 5.32%, #000000 94.32%);
}
.rtl .card.card-timeline .timeline .timeline-footer .btn.dropdown-toggle i {
  left: 0 !important; }

.rtl .card.card-timeline .timeline .timeline-footer .btn.dropdown-toggle:after {
  margin-left: 5px !important; }

.card.card-timeline .card-body {
  padding-left: 0;
  padding-right: 0; }

.card.card-timeline .timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative; }
.card.card-timeline .timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #222a42;
  left: 50%;
  margin-left: -1.5px; }
.card.card-timeline .timeline .timeline-footer .btn {
  margin: 0; }
.card.card-timeline .timeline .timeline-footer .btn.dropdown-toggle i {
  top: -1px;
  left: 10px; }
.card.card-timeline .timeline .timeline-footer .btn.dropdown-toggle:after {
  margin-left: 20px !important; }
.card.card-timeline .timeline h6 {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  margin: 10px 0px 0px; }
.card.card-timeline .timeline.timeline-simple:before {
  left: 5%; }
.card.card-timeline .timeline.timeline-simple > li > .timeline-panel {
  width: 86%; }
.card.card-timeline .timeline.timeline-simple > li > .timeline-badge {
  left: 5%; }
.card.card-timeline .timeline > li {
  margin-bottom: 20px;
  position: relative; }
.card.card-timeline .timeline > li:before, .card.card-timeline .timeline > li:after {
  content: " ";
  display: table; }
.card.card-timeline .timeline > li:after {
  clear: both; }
.card.card-timeline .timeline > li > .timeline-panel {
  background: #27293d;
  width: 45%;
  float: left;
  padding: 20px;
  border-radius: 0.2857rem;
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1);
  color: #222a42;
  margin-bottom: 20px;
  position: relative; }
.card.card-timeline .timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #222a42;
  border-right: 0 solid #222a42;
  border-bottom: 15px solid transparent;
  content: " "; }
.card.card-timeline .timeline > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #27293d;
  border-right: 0 solid #27293d;
  border-bottom: 14px solid transparent;
  content: " "; }
.card.card-timeline .timeline > li > .timeline-panel.timeline-panel-white {
  background: #ffffff; }
.card.card-timeline .timeline > li > .timeline-panel.timeline-panel-white .timeline-body p {
  color: #1d253b; }
.card.card-timeline .timeline > li > .timeline-panel.timeline-panel-white:after, .card.card-timeline .timeline > li > .timeline-panel.timeline-panel-white:before {
  border-right-color: #ffffff;
  border-left-color: #ffffff; }

.card.card-timeline .timeline > li > .timeline-badge {

  color: #ffffff;
  width: 50px;
  height: 50px;
  line-height: 51px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 50%;
  margin-left: -25px;

  z-index: 100;
}
.timeline-badge img {
  border-radius: 50%;
}
.card.card-timeline .timeline > li > .timeline-badge [class^="ti-"],
.card.card-timeline .timeline > li > .timeline-badge [class*=" ti-"] {
  line-height: inherit; }
.card.card-timeline .timeline > li > .timeline-badge .tim-icons {
  width: 25px;
  height: 21px;
  text-align: center; }
.card.card-timeline .timeline > li.timeline-inverted > .timeline-panel {
  float: right; }
.card.card-timeline .timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto; }
.card.card-timeline .timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto; }

.card.card-timeline .timeline-heading {
  margin-bottom: 15px; }

.card.card-timeline .timeline-badge.primary {
  background-color: #1d8cf8 !important; }

.card.card-timeline .timeline-badge.info {
  background-color: #1d8cf8 !important; }

.card.card-timeline .timeline-badge.success {
  background-color: #00bf9a !important; }

.card.card-timeline .timeline-badge.warning {
  background-color: #ff8d72 !important; }

.card.card-timeline .timeline-badge.danger {
  background-color: #fd5d93 !important; }

.card.card-timeline .timeline-title {
  margin-top: 0;
  color: inherit; }

.card.card-timeline .timeline-body > p,
.card.card-timeline .timeline-body > ul {
  margin-bottom: 0;
  color: #ffffff; }

.card.card-timeline .timeline-body > p + p {
  margin-top: 5px; }

@media (max-width: 767.98px) {
  .card.card-timeline .timeline:before {
    left: 5%; }
  .card.card-timeline .timeline > li > .timeline-badge {
    left: 5%; }
  .card.card-timeline .timeline > li > .timeline-panel {
    float: right;
    width: 83% !important; }
  .card.card-timeline .timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto; }
  .card.card-timeline .timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto; } }
.white-content .card-timeline .timeline > li.timeline-inverted > .timeline-panel {
  background: #ffffff; }
.white-content .card-timeline .timeline > li.timeline-inverted > .timeline-panel .timeline-body p {
  color: #1d253b; }
.white-content .card-timeline .timeline > li.timeline-inverted > .timeline-panel:after, .white-content .card-timeline .timeline > li.timeline-inverted > .timeline-panel:before {
  border-right-color: #ffffff;
  border-left-color: #ffffff; }
.white-content .card-timeline .timeline:before {
  background-color: #E3E3E3; }
